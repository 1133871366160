const CONSTANTS = {
  live: {
    appUrl: "https://my.setmore.com",
    loginUrl: "https://go.setmore.com",
    universalSignupUrl: "https://signup.setmore.com",
    clientWebAccess: "https://live-cwa.appspot.com",
  },
  staging: {
    appUrl: "https://staging.setmore.com",
    loginUrl: "https://go.staging.setmore.com",
    universalSignupUrl: "https://signup.staging.setmore.com",
    clientWebAccess: "https://stagingclientwebaccess-hrd.appspot.com",
  },
  assetsBaseUrl: {
    cdn: "https://assets.setmore.com/website",
    gcs: "https://storage.googleapis.com",
  },
};
const gutterCalculator = (gutterClass, cardIndex) => {
  let centralCardIndex = 1;
  let hasFourthIndex = 4;
  let hasSeventhIndex = 7;
  if (
    cardIndex === centralCardIndex ||
    cardIndex === hasFourthIndex ||
    cardIndex === hasSeventhIndex
  ) {
    centralCardIndex += 3;
    return gutterClass;
  } else {
    return "";
  }
};

const IterateAndAddClass = (elementToIterateOver, classToAdd) => {
  if (elementToIterateOver === undefined || elementToIterateOver === null) {
    return;
  }

  elementToIterateOver.forEach((element) => {
    element.classList.add(classToAdd);
  });
};
const IterateAndRemoveClass = (elementToIterateOver, classToRemove) => {
  if (elementToIterateOver === undefined || elementToIterateOver === null) {
    return;
  }
  elementToIterateOver.forEach((element) => {
    element.classList.remove(classToRemove);
  });
};
const IterateAndToggleClass = (
  elementToIterateOver,
  classToAdd,
  classToRemove
) => {
  if (elementToIterateOver === undefined || elementToIterateOver === null) {
    return;
  }
  elementToIterateOver.forEach((element) => {
    element.classList.add(classToAdd);
    element.classList.remove(classToRemove);
  });
};

const removeToast = (setToastMsg, duration = 3000) => {
  setTimeout(() => {
    setToastMsg("");
  }, duration);
};

const getParamFromUrl = (param) => {
  if (typeof window !== "undefined") {
    return new URL(window.location.href).searchParams.get(param);
  } else {
    return "";
  }
};

const getLocalStorageItem = (item) => {
  if (typeof window === "undefined") return null;

  let storedItem;
  try {
    storedItem = JSON.parse(window.localStorage.getItem(item));
  } catch {
    // If there was an error in parsing, which means the value was plain text
    // remove the item and return null
    window.localStorage.removeItem(item);
    return null;
  }

  if (storedItem === null) return null;

  const { expiry, value } = storedItem;
  if (expiry && Date.now() > expiry) {
    window.localStorage.removeItem(item);
    return null;
  }

  return value;
};

const setLocalStorageItem = (key, value, ttl = 7) => {
  if (typeof window === "undefined") return;

  // default ttl is 7 days
  // Maximum ttl is 365 days
  ttl = Math.min(ttl, 365);

  const storedItem = {
    value: value,
    expiry: Date.now() + ttl * 24 * 60 * 60 * 1000, // ttl is converted to milliseconds
  };
  window.localStorage.setItem(key, JSON.stringify(storedItem));
};

const removeLocalStorageItem = (key) => {
  if (typeof window !== "undefined") {
    return window.localStorage.removeItem(key);
  }
  return "";
};

const isMobileScreen = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth <= 768 ? true : false;
  }
};

const isTabletScreen = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth >= 768 && window.innerWidth <= 992 ? true : false;
  }
};

const getAppMode = (host) => {
  if (host.includes("livewebsite-setmore") || host.includes("setmore.com")) {
    return "live";
  } else {
    return "staging";
  }
};

const gsiScript = () => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://accounts.google.com/gsi/client";
  script.defer = "defer";
  document.head.appendChild(script);
};

function getCookie(name) {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      const cookieValue = cookie.substring(cookieName.length, cookie.length);
      try {
        return JSON.parse(cookieValue);
      } catch (error) {
        console.error(`Error parsing cookie value for key '${name}':`, error);
        return cookieValue;
      }
    }
  }

  return null;
}

function setCookie(name, value, days, domain) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  let cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
  if (domain) {
    cookie += `; domain=${domain}`;
  }
  document.cookie = cookie;
}

async function validateRefCodeWithUrl(url, referralCode = null) {
  try {
    // Remove the trailing slash from the URL, if it exists
    const sanitizedUrl = url.endsWith("/") ? url.slice(0, -1) : url;
    const requestUrl = referralCode
      ? `${sanitizedUrl}/${referralCode}`
      : sanitizedUrl;
    const response = await fetch(requestUrl);
    const data = await response.json();
    return response.ok && data.valid === true;
  } catch (error) {
    console.error("Error validating referral code:", error);
    return false;
  }
}

function getUrlAsObject(url) {
  const urlObj = new URL(url);
  const params = {};

  for (const [key, value] of new URLSearchParams(urlObj.search).entries()) {
    params[key] = value;
  }

  return {
    domain: urlObj.hostname,
    ...params,
  };
}

function setCreatedFrom(cookieName, url, urlSourceKey, storageKey) {
  if (typeof window === "undefined") return;

  const cookieExists = !!getCookie(cookieName);
  if (!cookieExists) return;

  const sourceValue = getUrlAsObject(url)[urlSourceKey];
  if (!sourceValue) return;

  const validSourceValues = [
    "bookingpage",
    "completebookingpage",
    "simplebookingpage",
    "slcbookingpage",
  ];
  if (!validSourceValues.includes(sourceValue)) return;

  setLocalStorageItem(storageKey, sourceValue);

  // todo modify this code when switching from localStorage to state in the future
  // we will modify this function iteratively in the future
  // https://anywhereworks.atlassian.net/browse/SW-839
}

// https://stackoverflow.com/questions/30007853/simple-easing-function-in-javascript
// https://gizma.com/easing/
function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

const carouselAction = () => {
  const carousel = {
    container: document.querySelector(".carousel"),
    item: Array.prototype.slice.call(
      document.querySelectorAll(".carousel-item")
    ),
  };
  const playFocusedCardVideo = function playFcv() {
    carousel.container.appendChild(carousel.container.firstElementChild);
    carousel.container.style.transition = "none";
    if (window.innerWidth > 768) {
      carousel.container.style.transform = "translate(-295px)";
    } else {
      carousel.container.style.transform = "translate(-280px)";
    }
    setTimeout(function () {
      carousel.container.style.transition = "all 0.8s";
    });
  };
  function moveCarousel() {
    if (window.innerWidth > 768) {
      carousel.container.style.transform = "translate(-555px)";
    } else {
      carousel.container.style.transform = "translate(-500px)";
    }
  }
  carousel.container.addEventListener("transitionend", function (e) {
    if (e.target != this) {
      return;
    } else {
      playFocusedCardVideo();
    }
  });
  setInterval(moveCarousel, 4000);
};

// Function to track button focus event
const trackButtonFocus = (buttonId) => () => {
  // Push the event name to the dataLayer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: buttonId });
};

const scrollToSelectedSection = (topValue) => {
  const getItemElements = document.querySelectorAll(".getItem");

  getItemElements.forEach(() => {
    window.scrollTo({
      top: topValue,
      behavior: "smooth",
    });
  });
};

export {
  gutterCalculator,
  IterateAndAddClass,
  IterateAndRemoveClass,
  IterateAndToggleClass,
  removeToast,
  getParamFromUrl,
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  isMobileScreen,
  isTabletScreen,
  CONSTANTS,
  gsiScript,
  getAppMode,
  getCookie,
  setCookie,
  validateRefCodeWithUrl,
  getUrlAsObject,
  setCreatedFrom,
  easeInOutCubic,
  trackButtonFocus,
  carouselAction,
  scrollToSelectedSection,
};
